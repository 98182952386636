import { z } from "zod";
const packageJsonInputSchema = z.object({
  name: z.string(),
  version: z.string(),
  description: z.string(),
  affinePlugin: z.object({
    release: z.union([z.boolean(), z.enum(["development"])]),
    entry: z.object({
      core: z.string()
    })
  })
});
const packageJsonOutputSchema = z.object({
  name: z.string(),
  version: z.string(),
  description: z.string(),
  affinePlugin: z.object({
    release: z.union([z.boolean(), z.enum(["development"])]),
    entry: z.object({
      core: z.string()
    }),
    assets: z.array(z.string())
  })
});
class HandlerManager {
  constructor() {
    this._initialized = false;
    throw new Error("Method not implemented.");
  }
  registerHandlers(handlers) {
    if (this._initialized) {
      throw new Error("Already initialized");
    }
    this._handlers = handlers;
    for (const [name, handler] of Object.entries(this._handlers)) {
      this._app.handle(`${this._namespace}:${name}`, async (...args) => handler(...args));
    }
    this._initialized = true;
  }
  invokeHandler(name, ...args) {
    return this._handlers[name](...args);
  }
  static getInstance() {
    throw new Error("Method not implemented.");
  }
}
export {
  HandlerManager,
  packageJsonInputSchema,
  packageJsonOutputSchema
};
