import { atom } from "jotai/vanilla";
import { atomEffect } from "jotai-effect";
const INTERNAL_BLOCKSUITE_HASH_MAP = /* @__PURE__ */ new Map([]);
const workspaceActiveAtomWeakMap = /* @__PURE__ */ new WeakMap();
const workspaceActiveWeakMap = /* @__PURE__ */ new WeakMap();
const workspaceEffectAtomWeakMap = /* @__PURE__ */ new WeakMap();
async function waitForWorkspace(workspace) {
  if (workspaceActiveWeakMap.get(workspace) !== true) {
    const providers = workspace.providers.filter(
      (provider) => "active" in provider && provider.active === true
    );
    for (const provider of providers) {
      provider.sync();
      await provider.whenReady;
    }
    workspaceActiveWeakMap.set(workspace, true);
  }
}
function getWorkspace(id) {
  if (!INTERNAL_BLOCKSUITE_HASH_MAP.has(id)) {
    throw new Error("Workspace not found");
  }
  return INTERNAL_BLOCKSUITE_HASH_MAP.get(id);
}
function getBlockSuiteWorkspaceAtom(id) {
  if (!INTERNAL_BLOCKSUITE_HASH_MAP.has(id)) {
    throw new Error("Workspace not found");
  }
  const workspace = INTERNAL_BLOCKSUITE_HASH_MAP.get(id);
  if (!workspaceActiveAtomWeakMap.has(workspace)) {
    const baseAtom = atom(async () => {
      await waitForWorkspace(workspace);
      return workspace;
    });
    workspaceActiveAtomWeakMap.set(workspace, baseAtom);
  }
  if (!workspaceEffectAtomWeakMap.has(workspace)) {
    const effectAtom = atomEffect(() => {
      const providers = workspace.providers.filter(
        (provider) => "passive" in provider && provider.passive === true
      );
      providers.forEach((provider) => {
        provider.connect();
      });
      return () => {
        providers.forEach((provider) => {
          provider.disconnect();
        });
      };
    });
    workspaceEffectAtomWeakMap.set(workspace, effectAtom);
  }
  return [
    workspaceActiveAtomWeakMap.get(workspace),
    workspaceEffectAtomWeakMap.get(workspace)
  ];
}
export {
  INTERNAL_BLOCKSUITE_HASH_MAP,
  getBlockSuiteWorkspaceAtom,
  getWorkspace,
  waitForWorkspace
};
