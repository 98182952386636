import { assertExists } from "@blocksuite/global/utils";
import { createStore, atom } from "jotai/vanilla";
import { getBlockSuiteWorkspaceAtom } from "./__internal__/workspace.js";
import "jotai-effect";
let rootStore = createStore();
function getCurrentStore() {
  return rootStore;
}
function _setCurrentStore(store) {
  rootStore = store;
}
const loadedPluginNameAtom = atom([]);
const currentWorkspaceIdAtom = atom(null);
const currentPageIdAtom = atom(null);
const currentWorkspaceAtom = atom(async (get) => {
  const workspaceId = get(currentWorkspaceIdAtom);
  assertExists(workspaceId);
  const [currentWorkspaceAtom2] = getBlockSuiteWorkspaceAtom(workspaceId);
  return get(currentWorkspaceAtom2);
});
const contentLayoutBaseAtom = atom("editor");
const contentLayoutAtom = atom(
  (get) => get(contentLayoutBaseAtom),
  (_, set, layout) => {
    set(contentLayoutBaseAtom, (prev) => {
      let setV;
      if (typeof layout !== "function") {
        setV = () => layout;
      } else {
        setV = layout;
      }
      const nextValue = setV(prev);
      if (nextValue === "editor") {
        return nextValue;
      }
      if (nextValue.first !== "editor") {
        throw new Error("The first element of the layout should be editor.");
      }
      if (nextValue.splitPercentage && nextValue.splitPercentage < 70) {
        throw new Error("The split percentage should be greater than 70.");
      }
      return nextValue;
    });
  }
);
export {
  _setCurrentStore,
  contentLayoutAtom,
  currentPageIdAtom,
  currentWorkspaceAtom,
  currentWorkspaceIdAtom,
  getCurrentStore,
  loadedPluginNameAtom
};
