import { assertExists } from "@blocksuite/global/utils";
import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai/vanilla";
const builtinPluginPaths = new Set(runtimeConfig.builtinPlugins);
const pluginCleanupMap = /* @__PURE__ */ new Map();
function addCleanup(pluginName, cleanup) {
  if (!pluginCleanupMap.has(pluginName)) {
    pluginCleanupMap.set(pluginName, /* @__PURE__ */ new Set());
  }
  const cleanupSet = pluginCleanupMap.get(pluginName);
  assertExists(cleanupSet);
  cleanupSet.add(cleanup);
  return () => {
    cleanupSet.delete(cleanup);
  };
}
function invokeCleanup(pluginName) {
  var _a;
  (_a = pluginCleanupMap.get(pluginName)) == null ? void 0 : _a.forEach((cleanup) => cleanup());
  pluginCleanupMap.delete(pluginName);
}
const pluginPackageJson = atom([]);
const enabledPluginAtom = atomWithStorage("affine-enabled-plugin", [
  "@affine/image-preview-plugin",
  "@affine/outline-plugin"
]);
const pluginHeaderItemAtom = atom({});
const pluginSettingAtom = atom(
  {}
);
const pluginEditorAtom = atom({});
const pluginWindowAtom = atom({});
export {
  addCleanup,
  builtinPluginPaths,
  enabledPluginAtom,
  invokeCleanup,
  pluginEditorAtom,
  pluginHeaderItemAtom,
  pluginPackageJson,
  pluginSettingAtom,
  pluginWindowAtom
};
